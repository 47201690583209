import TransitionLink from "gatsby-plugin-transition-link";
import { Power2, TimelineLite, TweenLite } from "gsap/all";
// NEEDED FOR GSAP TO WORK ON PRODUCTION
import CSSPlugin from "gsap/CSSPlugin";
// @ts-ignore
const C = CSSPlugin;

import React, { useEffect, useRef, useState } from "react";

import SEO from "../components/seo";
import { onInitPageLoadTracking, sendGA } from "../helpers/tracking";

const IndexPage = ({ location }: { location: any }) => {
  // STATES
  const [duration] = useState<number>(0.4);

  // REFS
  const $page = useRef(null);
  const $text = useRef<HTMLDivElement>(null);
  const $cta = useRef(null);

  useEffect(() => {
    enterAnimation();
    const linkedinPartnerId = String(process.env.GATSBY_LINKEDIN_PARTNER_ID);
    onInitPageLoadTracking();
  }, []);

  const enterAnimation = () => {
    const txtNodes = $text.current?.childNodes || [];
    const ctaNode = $cta.current;
    const tl = new TimelineLite({
      delay: 0.2,
    });

    tl.set([txtNodes, ctaNode], { alpha: 0 })
      .set($page.current, { alpha: 1 })
      .fromTo(txtNodes[0], 0.8, { x: 30, ease: Power2.easeOut }, { alpha: 1, x: 0 })
      .addLabel("subtext", "+=0.2")
      .fromTo(txtNodes[1], 1, { y: 26 }, { alpha: 1, y: 0, ease: Power2.easeOut }, "subtext-=0.3")
      .fromTo(txtNodes[2], 1.2, { y: 30 }, { alpha: 1, y: 0, ease: Power2.easeOut }, "subtext-=0.2")
      .fromTo(ctaNode, 0.6, { x: -20 }, { alpha: 1, x: 0 }, "-=0.3");
  };

  const exitAnimation = (_exit: any, node: any) => {
    TweenLite.to(node, duration, { alpha: 0 });
  };

  return (
    <>
      <SEO
        title="Home"
        description="Une fiche de poste, ça ne représente pas forcément la réalité d’un métier. Dites-nous ce que vous aimez, on vous dit quel métier vous correspond."
        meta={{
          "og:image": "https://elinoi-uploads.s3.eu-west-3.amazonaws.com/visuel-partage-jobs-en-startup.jpg",
          "og:image:height": "566",
          "og:image:secure_url": "https://elinoi-uploads.s3.eu-west-3.amazonaws.com/visuel-partage-jobs-en-startup.jpg",
          "og:image:width": "1080",
          "og:title": "Trouvez le job en startup qui vous correspond",
          "og:url": location.href,
        }}
      />
      <div ref={$page} className={"home-page-container page-container "} style={{ opacity: 0 }}>
        <div ref={$text} className="text-flex-container">
          <h1 className="title-text">Dites-nous ce que vous aimez, on vous dit quel métier vous correspond.</h1>
          <p className="text">
            Une fiche de poste c'est souvent flou ou compliqué à saisir. Ça ne représente pas forcément la réalité d'un
            métier ou de ce qui vous plaît.
          </p>
          <p className="text strong">Et si on prenait le problème à l'envers ? </p>
        </div>
        <div ref={$cta} className="cta-flex-container">
          <div className="cta-container">
            <TransitionLink
              className="cta"
              onClick={() =>
                sendGA("click", {
                  event_label: "LB - Commencer",
                })
              }
              to="/quiz"
              exit={{
                length: duration,
                trigger: ({ exit, node }: { exit: any; node: any }) => exitAnimation(exit, node),
              }}
              entry={{
                delay: duration,
              }}
            >
              Commencer
            </TransitionLink>
          </div>
        </div>
      </div>
    </>
  );
};

export default IndexPage;
